import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// teste
// teste
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
//import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {pegarParametroUrl} from './utils/utils.js';
import AdminLayout from "layouts/Admin.js";
import InicioLayout from "layouts/Inicio";
import VariaveisGlobais from "variaveis_globais/variaveis_globais";
import {IntlProvider} from 'react-intl';

if ((document.location.href.includes('localhost') == false )  &&  (pegarParametroUrl('idEmpresa') == null || pegarParametroUrl('idUsuario') == null || pegarParametroUrl('token') == null)) {
  ReactDOM.render(
    document.getElementById("root")
  );
} else {
  if (document.location.href.includes('localhost')) {
    VariaveisGlobais.idClinica = '1028';
    VariaveisGlobais.idUsuario = 13284
    VariaveisGlobais.token = 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIxMzI4NCIsInVuaXF1ZV9uYW1lIjoid2V2ZXJ0b24iLCJuYmYiOjE2NTA5MjE1MDgsImV4cCI6MTgwODY4NzkwOCwiaWF0IjoxNjUwOTIxNTA4LCJpc3MiOiJnc2RvY3Rvci5jb20uYnIiLCJhdWQiOiJodHRwczovL3d3dy5nc2RvY3Rvci5jb20uYnIifQ.BwYo_H3wvr_WwlDzCG416zLRiEH7W1TxUQAwUPFV6SSgIYsi5HTepa-ZKNymruX1A_HcPjWlkdSj3yOp844JOg'
  } else {
    VariaveisGlobais.idClinica = pegarParametroUrl('idEmpresa');
    VariaveisGlobais.idUsuario = pegarParametroUrl('idUsuario');
    VariaveisGlobais.token = pegarParametroUrl('token');
  }
  ReactDOM.render(
    <IntlProvider locale='pt'>
    <BrowserRouter>
      <Switch>
        {/* <Route path='/inicio' render={(props) => <InicioLayout {...props} /> } /> */}
        <Route path="/qrcode" render={(props) => <InicioLayout {...props} /> } />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Redirect from="/" to="/admin/Envios" />
        
      </Switch>
    </BrowserRouter>
    </IntlProvider>,
    document.getElementById("root")
  ); // teste
}
