import Select2Component from 'components/select2Component';
import React, {forwardRef, useEffect, useState} from 'react';
import axios from 'axios';
import VariaveisGlobais from 'variaveis_globais/variaveis_globais';

export var AgendaSelect2Component = forwardRef((props, ref) => {
    
    const [items, setaItems] = useState([]);

    useEffect(() => {
        axios.get(`https://www.gsdoctor.com.br/service/api/AgendaRecursos/GetComboAgendaRecursos?idEmpresa=${VariaveisGlobais.idClinica}`, {
            headers: {
                Authorization: 'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIxMCIsInVuaXF1ZV9uYW1lIjoid2V2ZXJ0b24iLCJuYmYiOjE1OTYyMTg1NTYsImV4cCI6MTc1Mzk4NDk1NiwiaWF0IjoxNTk2MTMyMTU2LCJpc3MiOiJnc2RvY3Rvci5jb20uYnIiLCJhdWQiOiJodHRwczovL3d3dy5nc2RvY3Rvci5jb20uYnIifQ.xunNgN9KTKGYZhykphID4Purok1z_LVO__YfDyqgQ-7ZAUvNzweVQo-8fdxYW0HPd8IrYYujxVL-Bxgao-7N1g'
            }
        }).then( (requisicao) => {
            if (requisicao.status == 200) {
                const agendas = requisicao.data.map((v) => {
                    return {
                       label: v.NomeRecurso,
                       value: v.Id
                    }
                });
                agendas.unshift({
                    value: 'TodasAgendas',
                    label: 'Todos'
                });
                setaItems(agendas);
            }
        }).catch(() => {

        });

    },[])

    return (
        <Select2Component   isMulti={true} ref={ref} id={props.id}  items={items}/>
    )
});