import React, {Component, forwardRef, useId, useImperativeHandle, useRef, useState,} from 'react';
import Select from 'react-select';

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];

  

var Select2Component = forwardRef((props, ref) => {
    const [selectedOption, setSelectedOptions] = useState(null);



    return (
        <Select id={useId} ref={ref} isMulti={props.isMulti == true} defaultValue={selectedOption} onChange={setSelectedOptions} options={props && props.items? props.items : options}></Select>
    )
});

export default Select2Component;