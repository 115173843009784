import { alterarConfiguracao, pegarClinicaConfiguracao, pegarConfiguracao, salvarConfiguracao, salvarMensagemPronta, carregaMensagensProntas, deletarMensagemPronta } from "../data/api/gsConnectApi";
import React, { forwardRef, useEffect, useState, useRef, setState } from "react";
import ReactDOMServer from 'react-dom/server';
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import axios from 'axios';
import VariaveisGlobais from 'variaveis_globais/variaveis_globais';
import {MdDelete} from 'react-icons/md';

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  FormLabel,
  ListGroup,
  Table,
  FormText
} from "react-bootstrap";

function MensagensProntasView() {
  
  return (
    <>
      <Container fluid>
      <MensagensProntasComponent />
      </Container>
    </>
  );
}

class MensagensProntasComponent extends React.Component {
  

  constructor(props) {
    super(props);
    this.state = {
      mensagensProntas: [],
    }
    this.mensagemConteudo = React.createRef();
  }



  componentWillReceiveProps(props) {
    this.state.mensagensProntas = props.mensagensProntas;
    this.setState(this.state);
  }


  componentDidMount() {
    this.CarregarMensagensProntas();
  }

  CarregarMensagensProntas() {
    carregaMensagensProntas().then((dados) => {
      this.state.mensagensProntas = !dados || dados.length == 0? [] :dados;
      this.setState(this.state);
      console.log('Atualizar status');
    }).catch(erro => {
      console.log(erro);
    })
  }

  salvarMensagemPronta(conteudo) {
    salvarMensagemPronta(conteudo).then(() => {
      CarregarMensagensProntas();
          }).catch(() => {
      
          });
  }

  deletarMensagemPronta(obj) {
    deletarMensagemPronta(obj.id).then(() => {
      CarregarMensagensProntas();
          }).catch(() => {
      
          });
  }


  render(){
    return <>
    <Card>
    <Card.Header>
      <Card.Title style={{fontWeight: 600}}>Mensagens Prontas</Card.Title>
    </Card.Header>
    <Card.Body>
      <Form >
        <Row className="mt-3 align-items-center">
          <Col md={10}>
            <Form.Control  type="text" className="col-form-label" as="textarea" ref={this.mensagemConteudo}  style={{ height: 150 }}></Form.Control>
            </Col>
          <Col md="2"><Button variant="success"
              className="btn-fill"
              size="sm" onClick={() => {
            const mensagemConteudo = this.mensagemConteudo.current.value;
            
            // validar
            if (!mensagemConteudo) {
              alert('Preencha a mensagem.');
              return;
            }

            this.state.mensagensProntas.push({conteudo: mensagemConteudo});
            
            this.setState(this.state);
            this.salvarMensagemPronta(mensagemConteudo);
            // this.salvarConfiguracoes(this.state.configuracoes);
            // limpar os campos
            this.mensagemConteudo.current.value='';
          }}>Adicionar</Button></Col>
        </Row>
      </Form>
      <MensagensProntasTabelaComponent  mensagensProntas={this.state.mensagensProntas} quandoDeletar={this.deletarMensagemPronta}></MensagensProntasTabelaComponent>
    </Card.Body>
  </Card>
      
    </>
  }
}

class MensagensProntasTabelaComponent extends React.Component {

  
  constructor(props) {
    super(props);
    
    this.state = {
      mensagensProntas: []
    }
  }


  componentWillReceiveProps(props) {
    this.state.mensagensProntas = props.mensagensProntas;
    this.state.quandoDeletar = props.quandoDeletar;
    this.setState(this.state);
  }




  removeItem(index) {
    var item = this.state.mensagensProntas[index];
    this.state.quandoDeletar(item);
    this.state.mensagensProntas.splice(index, 1);
    
    this.setState(this.state);
  }

  render() {
    return <><Table striped bordered hover className="mt-3"> 
    <thead>
      <tr>
        <th style={{color: 'black', fontWeight: 600}}>Mensagem</th>
        <th></th>
      </tr>
    </thead>
    <tbody id="tbodyTabela">
        {this && this.state && this.state.mensagensProntas ? this.state.mensagensProntas.map((valor, index) => {
          
          return <tr>
            <td>{valor.conteudo}</td>
            <td><Button onClick={() => this.removeItem(index)} variant='danger' type="button" className="btn-sm">Excluir</Button></td>
          </tr>
        }): <></>}
    </tbody>
  </Table></>
  }
}




export default MensagensProntasView;
