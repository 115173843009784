/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import EnviosView from './views/EnviosView.js';
import TableList from "views/Mensagens";
import AutomacaoView from "views/Automacao";
import Typography from "views/Typography.js";
import AjudaView from "views/AjudaView.js";
import MensagensProntasView from "views/MensagensProntas.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import Upgrade from "views/Upgrade.js";

const dashboardRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: Dashboard,
  //   layout: "/admin",
  // },
  {
    path: "/Envios",
    name: "Envios",
    icon: "nc-icon",
    component: EnviosView,
    layout: "/admin",
  },
  {
    path: "/mensagens",
    name: "Mensagens",
    icon: "nc-icon",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/mensagensprontas",
    name: "Mensagens Prontas",
    icon: "nc-icon ",
    component: MensagensProntasView,
    layout: "/admin",
  },
  {
    path: "/automacao",
    name: "Automações",
    icon: "nc-icon",
    component: AutomacaoView,
    layout: "/admin",
  },
  {
    path: "/Ajuda",
    name: "Ajuda",
    icon: "nc-icon",
    component: AjudaView,
    layout: "/admin",
  },
  {
    path: "/configuracoes",
    name: "Configurações",
    icon: "nc-icon ",
    component: Typography,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
