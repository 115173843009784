import { alterarConfiguracao, pegarClinicaConfiguracao, pegarConfiguracao, salvarConfiguracao } from "../data/api/gsConnectApi";
import React, { forwardRef, useEffect, useState, useRef, setState } from "react";
import ReactDOMServer from 'react-dom/server';
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import axios from 'axios';
import VariaveisGlobais from 'variaveis_globais/variaveis_globais';
import { MdDelete } from 'react-icons/md';

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  FormLabel,
  ListGroup,
  Table,
} from "react-bootstrap";
import Select2Component from "components/select2Component";
import { TipoRespostaSelect2Component } from "components/select2/tipoRespotaSelect2Component";
import { fileToBase64 } from "utils/utils";
import { Label } from "reactstrap";
import Switch from "react-bootstrap/esm/Switch";
import { AgendaSelect2Component } from "components/select2/agendaSelect2Component";
import { ProcedimentosSelect2Component } from "components/select2/procedimentosSelect2Component";
import { StatusSelect2Component } from "components/select2/statusSelect2Component";
import { DiasAntesSelect2Component } from "components/select2/diasAntesSelect2Component";

function AutomacaoView() {

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h5">Automações</Card.Title>
                <p className="card-category">
                  Configuração de envios automáticos.
                </p>
              </Card.Header>
              <Card.Body className="">
                <Col md='12 mt-3'>
                  <Card>
                    <Card.Header>
                      <Card.Title style={{ fontWeight: 600 }}>Enviar mensagens de aniversário automaticamente</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <Row className="mt-3 ml-3">
                          Habilitado: <div className="ml-3 mr-3"><ConfiguracaoItem tipo="AUTOMACAO_ANIVERSARIANTES" /></div>Obs: As mensagens serão enviadas todos os dias às 08:00.
                        </Row>
                      </Form>
                    </Card.Body>
                  </Card>
                  <ConfiguracaoAgendamento titulo='Enviar confirmações de agendamentos automaticamente' configuracao='AUTOMACAO_CONFIRMACAO_AGENDAMENTO_CONFIGURACAO' configuracaoHabilitar='AUTOMACAO_CONFIRMACAO_AGENDAMENTO' />
                  <ConfiguracaoAgendamento titulo='Enviar lembretes de agendamentos automaticamente' configuracao='AUTOMACAO_LEMBRETE_AGENDAMENTO_CONFIGURACAO' configuracaoHabilitar='AUTOMACAO_LEMBRETE_AGENDAMENTO' />
                  <ConfiguracaoAgendamento titulo='Enviar pré cadastrado automaticamente (Apenas pacientes sem cadastro)' configuracao='AUTOMACAO_PRE_CADASTRO_AGENDAMENTO_CONFIGURACAO' configuracaoHabilitar='AUTOMACAO_PRE_CADASTRO_AGENDAMENTO' />
                </Col>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>
    </>
  );
}

class ConfiguracaoAgendamento extends React.Component {


  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      configuracoes: [],
      configuracao: props.configuracao
    }
    this.agendasRef = React.createRef();
    this.procedimentosRef = React.createRef();
    this.statusRef = React.createRef();
    this.diasAntesRef = React.createRef();
    this.horarioEnvioRef = React.createRef();
    this.mensagemConteudo = React.createRef();
  }



  componentWillReceiveProps(props) {
    this.state.configuracao = props.configuracao;
    this.state.configuracaoHabilitar = props.configuracaoHabilitar;
    this.setState(this.state);
  }


  componentDidMount() {
    this.carregarConfiguracoes();
  }

  carregarConfiguracoes() {
    pegarConfiguracao(this.props.configuracao).then((dados) => {
      this.state.configuracoes = !dados || dados.valor.length == 0 ? [] : JSON.parse(dados.valor);
      this.setState(this.state);
      console.log('Atualizar status');
    }).catch(erro => {
      console.log(erro);
    }, [])
  }

  salvarConfiguracoes(listaAtual) {
    alterarConfiguracao(this.configuracao ?? this.props.configuracao, JSON.stringify(listaAtual)).then(() => {

    }).catch(() => {

    });
  }


  render() {
    return <>
      <Card>
        <Card.Header>
          <Card.Title style={{ fontWeight: 600 }}>{this.props.titulo}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row className="mt-3 ml-1">
              Habilitado: <div className="ml-3 mr-3"><ConfiguracaoItem tipo={this.props.configuracaoHabilitar} /></div>
            </Row>
            <Row className="mt-3">
              <Col md={2}><Form.Text className="col-form-label">Agenda</Form.Text></Col>
              <Col md={3}><AgendaSelect2Component ref={this.agendasRef}></AgendaSelect2Component></Col>
              <Col md={2}><Form.Text className="col-form-label">Procedimentos</Form.Text></Col>
              <Col md={3}><ProcedimentosSelect2Component ref={this.procedimentosRef}></ProcedimentosSelect2Component></Col>
            </Row>
            <Row className="">
              <Col md={2}><Form.Text className="col-form-label">Status</Form.Text></Col>
              <Col md={3}><StatusSelect2Component ref={this.statusRef}></StatusSelect2Component></Col>
              <Col md={2}><Form.Text className="col-form-label">Notifica</Form.Text></Col>
              <Col md={3}><DiasAntesSelect2Component ref={this.diasAntesRef}></DiasAntesSelect2Component></Col>
            </Row>
            <Row className="mt-3 ml-1">
              Mensagem personalizada
              </Row>
            <Row>
              <ul>
                <li>$nomePaciente: inserir nome da paciente</li>
                <li>$nomeProcedimento: inserir procedimento agendado</li>
                <li>$nomeMedico: inserir nome do médico da paciente</li>
                <li>$nomeDiaDaSemana: inserir dia da semana</li>
                <li>$dataAgendamento: inserir data do agendamento</li>
                <li>$horarioAgendamento: inserir horário do agendamento</li>
              </ul>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Control type="text" className="col-form-label" as="textarea" ref={this.mensagemConteudo} style={{ height: 150 }}></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}><Form.Text className="col-form-label">Horário de início dos envios</Form.Text></Col>
              <Col md={3}><input type="time" className="form-control" ref={this.horarioEnvioRef}></input></Col>
              <Col md="auto"><Button variant="success" className="btn-fill form-control" size="md" onClick={() => {
                const agendasSelecionadas = this.agendasRef.current.getValue();
                const procedimentosSelecionadas = this.procedimentosRef.current.getValue();
                const statusSelecionadas = this.statusRef.current.getValue();
                const diasAntesSelecionadas = this.diasAntesRef.current.getValue();
                const horarioEnvio = this.horarioEnvioRef.current.value;
                const mensagemConteudo = this.mensagemConteudo.current.value;

                // validar
                if (agendasSelecionadas.length == 0) {
                  alert('Selecione uma agenda.');
                  return;
                }
                if (procedimentosSelecionadas.length == 0) { alert('Selecione um procedimento.'); return; }
                if (statusSelecionadas.length == 0) { alert('Selecione um status.'); return; }
                if (diasAntesSelecionadas.length == 0) { alert('Selecione dias antes.'); return; }
                if (!horarioEnvio) { alert('Preencha o horário de envio.'); return; }

                // verifica se já existe alguma agenda adicionada para adicionar todos
                if (agendasSelecionadas.find(p => p.label == 'Todos')) {
                  if (this.state.configuracoes.length > 0) {
                    // alert('Já existe uma configuração definida para todas as agendas.');
                    // return;
                  }
                }

                // pegar pela lista
                var novasConfiguracoes = [];
                for (let index = 0; index < agendasSelecionadas.length; index++) {
                  const agenda = agendasSelecionadas[index];
                  // validação se já existe uma configuração adicionada
                  // por nome e todos
                  const jaExiste = this.state.configuracoes.find(p => p.Agenda.Nome == agenda.label || p.Agenda.Nome == 'Todos');
                  if (jaExiste) {
                    // alert('Configuração já adiciona para essa agenda.');
                    // return;
                  }



                  novasConfiguracoes.push({
                    Agenda: {
                      Nome: agenda.label,
                      id: agenda.value,
                    },
                    Procedimentos: procedimentosSelecionadas.map((procedimento) => { return { Nome: procedimento.label, id: procedimento.value } }),
                    Status: statusSelecionadas.map((status) => { return { Nome: status.label, id: status.value } }),
                    DiasAntes: diasAntesSelecionadas[0].value,
                    DiasAntesLabel: diasAntesSelecionadas[0].label,
                    HorarioEnvio: horarioEnvio,
                    MensagemConteudo: mensagemConteudo
                  });
                }

                this.state.configuracoes.push(...novasConfiguracoes);

                this.setState(this.state);
                this.salvarConfiguracoes(this.state.configuracoes);
                // limpar os campos
                this.agendasRef.current.setValue(null);
                this.procedimentosRef.current.setValue(null);
                this.statusRef.current.setValue(null);
                this.diasAntesRef.current.setValue(null);
                this.horarioEnvioRef.current.value = '';
                this.mensagemConteudo.current.value = '';
              }}>Adicionar</Button></Col>
            </Row>
          </Form>
          <ConfiguracaoAgendamentoTabela configuracao={this.props.configuracao} configuracoes={this.state.configuracoes} quandoDeletar={this.salvarConfiguracoes}></ConfiguracaoAgendamentoTabela>
        </Card.Body>
      </Card>

    </>
  }
}

class ConfiguracaoAgendamentoTabela extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      configuracoes: []
    }
  }


  componentWillReceiveProps(props) {
    this.state.configuracoes = props.configuracoes;
    this.state.quandoDeletar = props.quandoDeletar;
    this.state.configuracao = props.configuracao;
    this.setState(this.state);
  }




  removeItem(index) {
    this.state.configuracoes.splice(index, 1);
    this.state.quandoDeletar(this.state.configuracoes);
    this.setState(this.state);
  }

  render() {
    return <><Table striped bordered hover className="mt-3">
      <thead>
        <tr>
          <th style={{ color: 'black', fontWeight: 600 }}>Agenda</th>
          <th style={{ color: 'black', fontWeight: 600 }}>Procedimentos</th>
          <th style={{ color: 'black', fontWeight: 600 }}>Status</th>
          <th style={{ color: 'black', fontWeight: 600 }}>Dias antes</th>
          <th style={{ color: 'black', fontWeight: 600 }}>Horário</th>
          <th style={{ color: 'black', fontWeight: 600 }}>Mensagem</th>
          <th></th>
        </tr>
      </thead>
      <tbody id="tbodyTabela">
        {this && this.state && this.state.configuracoes ? this.state.configuracoes.map((valor, index) => {

          return <tr>
            <td>{valor.Agenda.Nome}</td>
            <td>{valor.Procedimentos.map(procedimento => procedimento.Nome + '\n').join('')}</td>
            <td>{valor.Status.map(status => status.Nome + '\n').join('')}</td>
            <td>{valor.DiasAntesLabel}</td>
            <td>{valor.HorarioEnvio}</td>
            <td><div style={{overflow: 'scroll', maxHeight: '250px', maxWidth: '350px'}}>{valor.MensagemConteudo}</div></td>
            <td><Button onClick={() => this.removeItem(index)} variant='danger' type="button" className="btn-sm">Excluir</Button></td>
          </tr>
        }) : <></>}
      </tbody>
    </Table></>
  }
}


const ConfiguracaoItem = (props) => {

  const configuracaoReference = useRef();

  useEffect(() => {
    pegarConfiguracao(props.tipo).then((dados) => {
      configuracaoReference.current.checked = dados.valor == 'true';
    }).catch(erro => {
      console.log(erro);
    }, [])
  });

  return (
    <>
      <Form.Check type='switch' md='5' id={props.tipo} ref={configuracaoReference} onChange={(event) => {
        alterarConfiguracao(props.tipo, event.target.checked.toString()).then(() => {
          alert('Configuração salva.');
        }).catch(() => {

        });
      }}></Form.Check>

    </>
  );
}


export default AutomacaoView;
