import Select2Component from 'components/select2Component';
import React, {forwardRef, useEffect, useId, useState} from 'react';
import axios from 'axios';
import VariaveisGlobais from 'variaveis_globais/variaveis_globais';

export var DiasAntesSelect2Component = forwardRef((props, _ref) => {
    
    const [items, setaItems] = useState([]);

    useEffect(() => {
        var statues = [
            {
                value: '-15',
                label: '15 dias depois',
            },
            {
                value: '-14',
                label: '14 dias depois',
            },
            {
                value: '-13',
                label: '13 dias depois',
            },
            {
                value: '-12',
                label: '12 dias depois',
            },
            {
                value: '-11',
                label: '11 dias depois',
            },
            {
                value: '-10',
                label: '10 dias depois',
            },
            {
                value: '-9',
                label: '9 dias depois',
            },
            {
                value: '-8',
                label: '8 dias depois',
            },
            {
                value: '-7',
                label: '7 dias depois',
            },
            {
                value: '-6',
                label: '6 dias depois',
            },
            {
                value: '-5',
                label: '5 dias depois',
            },
            {
                value: '-4',
                label: '4 dias depois',
            },
            {
                value: '-3',
                label: '3 dias depois',
            },
            {
                value: '-2',
                label: '2 dias depois',
            },
            {
                value: '-1',
                label: '1 dia depois',
            },
            {
                value: '0',
                label: 'Mesmo dia',
            },
            {
                value: '1',
                label: '1 dia antes',
            },
            {
                value: '2',
                label: '2 dias antes',
            },
            {
                value: '3',
                label: '3 dias antes',
            },
            {
                value: '4',
                label: '4 dias antes',
            },
            {
                value: '5',
                label: '5 dias antes',
            },
            {
                value: '6',
                label: '6 dias antes',
            },
            {
                value: '7',
                label: '7 dias antes',
            },
            {
                value: '8',
                label: '8 dias antes',
            },
            {
                value: '9',
                label: '9 dias antes',
            },
            {
                value: '10',
                label: '10 dias antes',
            },
            {
                value: '11',
                label: '11 dias antes',
            },
            {
                value: '12',
                label: '12 dias antes',
            },
            {
                value: '13',
                label: '13 dias antes',
            },
            {
                value: '14',
                label: '14 dias antes',
            },
            {
                value: '15',
                label: '15 dias antes',
            },
          ]

          setaItems(statues);

    },[])

    return (
        <Select2Component  ref={_ref}  items={items}/>
    )
});