import Select2Component from 'components/select2Component';
import React, {forwardRef, useEffect, useState} from 'react';
import axios from 'axios';
import VariaveisGlobais from 'variaveis_globais/variaveis_globais';


export var ProfissionaisSelect2Component = forwardRef((props, _ref) => {
    
    const [profissionais, setaProfissionais] = useState([]);

    useEffect(() => {
        axios.get(`https://www.gsdoctor.com.br/service/api/Profissionais/GetProfissionaisCadastrados?idEmpresa=${VariaveisGlobais.idClinica}&profExterno=false&profIndicante=false&ativo=true`, {
            headers: {
                Authorization: 'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIxMCIsInVuaXF1ZV9uYW1lIjoid2V2ZXJ0b24iLCJuYmYiOjE1OTYyMTg1NTYsImV4cCI6MTc1Mzk4NDk1NiwiaWF0IjoxNTk2MTMyMTU2LCJpc3MiOiJnc2RvY3Rvci5jb20uYnIiLCJhdWQiOiJodHRwczovL3d3dy5nc2RvY3Rvci5jb20uYnIifQ.xunNgN9KTKGYZhykphID4Purok1z_LVO__YfDyqgQ-7ZAUvNzweVQo-8fdxYW0HPd8IrYYujxVL-Bxgao-7N1g'
            }
        }).then( (requisicao) => {
            if (requisicao.status == 200) {
                const profissionaisObjeto = requisicao.data.map((v) => {
                    return {
                        value: v.id, label: v.NomeAbreviado
                    }
                });
                setaProfissionais(profissionaisObjeto);
            }
        }).catch(() => {

        })
    },[])

    return (
        <Select2Component ref={_ref}  items={profissionais}/>
    )
});