import React, { useEffect, useRef, useState } from "react";
import DataTable from 'react-data-table-component';
import { getMensagens } from '../data/api/gsConnectApi';
import { FormatarDateParaDDMMYYYY, FormatarStrDateParaDDMMYYYYHHMM, FormatarDateParaYYYYMMDD, gerarLinkDownload } from '../utils/utils';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  FormLabel,
  FormText,
  Form,
  Alert,
  Dropdown
} from "react-bootstrap";

import Select2Component from "components/select2Component";
import { TipoSelect2Component } from "components/select2/tipoSelect2Component";
import { ProfissionaisSelect2Component } from "components/select2/profissionaisSelect2Component";
import { ClientesSelect2Component } from "components/select2/clientesSelect2Component";

// react-bootstrap components



function EnviosView() {


  const [dataInicio, setDataInicio] = useState(FormatarDateParaYYYYMMDD(new Date(Date.now() - 	2592000000),));
  const [dataFim, setDataFim] = useState( FormatarDateParaYYYYMMDD(new Date(Date.now()),));
  const [mensagemErro, setMensagemErro] = useState('');

  const [messages, sentMessages] = useState([]);

  const enviadoParaReferencia = useRef(null);
  const enviadoPorReferencia = useRef(null);
  const tipoReferencia = useRef(null);



  useEffect(() => {
    // getMensagens().then(function (mensagens) {
    //   let msgs = [];
    //     for (const mensagem of mensagens) {
    //       msgs.push({ id: mensagem.id, data: mensagem.enviadoEm.toString(), enviadoPara: mensagem.nomeCliente, enviadoPor: mensagem.enviadoPor, tipo: mensagem.tipo, conteudo: mensagem.conteudo });
    //     }
    //     sentMessages(msgs);
    // }).catch(function () {

    // });
  }, [])



  return (
    <>
      <Container fluid>
        <Alert variant='danger' show={mensagemErro != ''} className="text-white" >
        {mensagemErro}
        </Alert>
        <Row className="align-items-center mb-3">
          <Col md='1'><Form.Label>Período</Form.Label></Col>
          <Col md='1'><Form.Label>início</Form.Label></Col>
          <Col md='2'>
            <Form.Control type="date" value={dataInicio} onChange={(elemento) => {setDataInicio(elemento.target.value)}}></Form.Control></Col>
          <Col md='1'><Form.Label>até</Form.Label></Col>
          <Col md='2'><Form.Control type="date" value={dataFim}  onChange={(elemento) => {setDataFim(elemento.target.value)}}></Form.Control></Col>
        </Row>
        <Row className="align-items-center mb-3">
          {/* <Col md='1'><Form.Label>Enviado para</Form.Label></Col>
          <Col md='2'>
            <ClientesSelect2Component ref={enviadoParaReferencia}/>
          </Col> */}
          <Col md='1'><Form.Label>Enviado por</Form.Label></Col>
          <Col md='2'>
            <ProfissionaisSelect2Component ref={enviadoPorReferencia} />
          </Col>
          <Col md='1'><Form.Label>Tipo</Form.Label></Col>
          <Col md='2'>
            <TipoSelect2Component ref={tipoReferencia} items={{ola: 'ola'}} />
          </Col>
          <Col>
            <Button size="sm" className="btn-fill" variant='success' onClick={() => {
              if (!dataInicio) {
                setMensagemErro('Preencha a data de inicio.');
                return;
              }

              if (!dataFim) {
                setMensagemErro('Preencha a data de fim.');
                return;
              }

              if (dataFim < dataInicio) {
                setMensagemErro('Data fim menor que data início.');
                return;
              }

              setMensagemErro('');

              const dataInicioCorrecao = new Date(Date.parse(dataInicio) + 86400000);
              const dataFimCorrecao = new Date(Date.parse(dataFim) + 86400000);
              const tipo = !tipoReferencia.current?.opcaoSelecionada || tipoReferencia.current?.opcaoSelecionada.value == 'SELECIONE' ? undefined : tipoReferencia.current.opcaoSelecionada.value;
              const idEnviadoPor = !enviadoPorReferencia.current?.opcaoSelecionada || enviadoPorReferencia.current?.opcaoSelecionada.value == 'SELECIONE' ? undefined : enviadoPorReferencia.current.opcaoSelecionada.value;
              const idEnviadoPara = !enviadoParaReferencia.current?.opcaoSelecionada || enviadoParaReferencia.current?.opcaoSelecionada.value == 'SELECIONE' ? undefined : enviadoParaReferencia.current.opcaoSelecionada.value;

              getMensagens(tipo, dataInicioCorrecao, dataFimCorrecao, idEnviadoPara, idEnviadoPor).then(function (mensagens) {
                sentMessages(mensagens);
              }).catch(function () {

              });
            }}>Pesquisar</Button>
          </Col>
        </Row>

        <DataTable title="Envios" defaultSortAsc={false} defaultSortFieldId={'colData'}
        columns={[
          {
            id: 'colData',
            name: 'Data',
            selector: 'data',
            sortable: true,
            cell: row => <label>{FormatarStrDateParaDDMMYYYYHHMM(row.criadoEm)}</label>
          },
          {
            name: 'Enviado Para',
            // selector: '',
            // sortable: true,
            // wrap: true,
            // cell: row => row.contato ? row.contato.nome : ''
          },
          {
            name: 'Enviado Por',
            // selector: '',
            // sortable: true,
            // wrap: true
          },
          {
            name: 'Tipo',
            // selector: 'tipo',
            // sortable: true,
            // wrap: true
          },
          {
            name: 'Conteúdo',
            // selector: '',
            // sortable: true,
            // wrap: true,
            // cell: (row) => <div style={{padding: 10, margin: 10}}>{conteudo(row)}</div>
          },
          {
            name: ' ',
            // selector: '',
            // sortable: true,
            // wrap: true,
            // cell: (row) => row.tipo != 'Resumo de ciclo' && row.tipo != 'Laudo Espermograma' && row.tipo != 'GED' ? <></> :  (<a href={'#'} onClick={() => {
            //   window.open(gerarLinkDownload(row.idClinica, row.idWhatsapp), '_blank');
            // }}>download</a>)
          } 
          //teste
        ]} data={messages} pagination='true' ></DataTable>
      </Container>
    </>
  );

}

const conteudo = (mensagem) => {
  if (mensagem.tipo == 'Aniversário' && mensagem.textoSemFormatacao && mensagem.textoSemFormatacao.includes('data:image')) return <img src={mensagem.textoSemFormatacao} style={{heigth: 250, width: 250}}></img>

  return mensagem.arquivo ? '' : mensagem.texto
}

export default EnviosView;
