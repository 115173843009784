import URL_API from '../../variaveis_globais/const';
import { FormatarDateParaDDMMYYYY } from '../../utils/utils';
import VariaveisGlobais from 'variaveis_globais/variaveis_globais';

const axios = require('axios');
export const getMensagens = function(tipo, dataInicio, dataFim, idEnviadoPara, idEnviadoPor) {
    VariaveisGlobais.token
    let query = `idClinica=${VariaveisGlobais.idClinica}`;
    if (tipo) query += `&tipo=${tipo}`
    if (dataInicio) query += `&dataInicio=${FormatarDateParaDDMMYYYY(dataInicio)}`
    if (dataFim) query += `&dataFim=${FormatarDateParaDDMMYYYY(dataFim)}`
    if (idEnviadoPara) query += `&idEnviadoPara=${idEnviadoPara}`
    if (idEnviadoPor) query += `&idEnviadoPor=${idEnviadoPor}`
    VariaveisGlobais
    return new Promise((resolve, reject) => {
        axios.get(`${URL_API}mensagem?${query}`,  {
            headers: { Authorization: `Bearer ${VariaveisGlobais.token}` }
        }).then(function (response) {
            console.log(response);
            if (response.data.error == true)  reject(true);
            resolve(response.data.data);
        }).catch(function(error) {
            console.log(error);
            reject(true);
        });
    });
}


export const pegarClientes = function() {
    let query = `idClinica=${VariaveisGlobais.idClinica}`;

    return new Promise((resolve, reject) => {
        axios.get(`${URL_API}client?${query}`, {
            headers: { Authorization: `Bearer ${VariaveisGlobais.token}` }
        }).then(function (response) {
            console.log(response);
            if (response.data.error == true)  reject(true);
            resolve(response.data.data);
        }).catch(function(error) {
            console.log(error);
            reject(true);
        });
    });
}

export const inicializarInstancia = function (numeroInstancia) {
    let query = `idClinica=${VariaveisGlobais.idClinica}&numeroInstancia=${numeroInstancia}`;
    return new Promise((resolve, reject) => {
        axios.get(`${URL_API}instance/init?${query}`, {
            headers: { Authorization: `Bearer ${VariaveisGlobais.token}` }
        }).then(function (response) {
            console.log(response);
            if (response.data.error == true)  reject(true);
            resolve(response.data);
        }).catch(function(error) {
            alert(error.response.data.message);
            reject(true);
        });
    });
}

export const qrCodeInstancia = function (numeroInstancia) {
    let query = `idClinica=${VariaveisGlobais.idClinica}&numeroInstancia=${numeroInstancia}`;
    return new Promise((resolve, reject) => {
        axios.get(`${URL_API}instance/qr?${query}`, {
            headers: { Authorization: `Bearer ${VariaveisGlobais.token}` }
        }).then(function (response) {
            console.log(response);
            if (response.data.error == true)  reject(true);
            resolve(response.data);
        }).catch(function(error) {
            alert(error.response.data.message);
            reject(true);
        });
    });
}

export const estaConectado = function (numeroInstancia) {
    let query = `idClinica=${VariaveisGlobais.idClinica}&numeroInstancia=${numeroInstancia}`;
    return new Promise((resolve, reject) => {
        axios.get(`${URL_API}instance/info?${query}`, {
            headers: { Authorization: `Bearer ${VariaveisGlobais.token}` }
        }).then(function (response) {
            if (response.data.error == true)  reject(true);
            resolve(response.data);
        }).catch(function(error) {
            console.log(error);
            reject(true);
        });
    });
}


export const deslogarInstancia = function (numeroInstancia) {
    let query = `idClinica=${VariaveisGlobais.idClinica}&numeroInstancia=${numeroInstancia}`;
    return new Promise((resolve, reject) => {
        axios.post(`${URL_API}instance/logout?${query}`, {
            idClinica: VariaveisGlobais.idClinica
        }, {
            headers: { Authorization: `Bearer ${VariaveisGlobais.token}` }
        }).then(function (response) {
            console.log(response);
            if (response.data.error == true)  reject(true);
            resolve(response.data.data);
        }).catch(function(error) {
            console.log(error);
            reject(true);
        });
    });
}

export const pegarConfiguracao = function(tipo) {
    let query = `idClinica=${VariaveisGlobais.idClinica}&nome=${tipo}`;
    return new Promise((resolve, reject) => {
        axios.get(`${URL_API}clinica/configuracao?${query}`, {
            headers: { Authorization: `Bearer ${VariaveisGlobais.token}` }
        }).then(function (response) {
            console.log(response);
            if (response.data.error == true)  reject(true);
            resolve(response.data.data);
        }).catch(function(error) {
            console.log(error);
            reject(true);
        });
    });
}

export const salvarMensagemPronta = function(conteudo) {
    return new Promise((resolve, reject) => {
        axios.post(`${URL_API}mensagem/prontas`, {
            idClinica: VariaveisGlobais.idClinica,
            conteudo: conteudo,
        }, {
            headers: { Authorization: `Bearer ${VariaveisGlobais.token}` }
        }).then(function (response) {
            console.log(response);
            if (response.data.error == true)  reject(true);
            resolve(response.data.data);
        }).catch(function(error) {
            console.log(error);
            reject(true);
        });
    });
}


export const deletarMensagemPronta = function(id) {
    return new Promise((resolve, reject) => {
        axios.post(`${URL_API}mensagem/prontas/deletar`, {
            idClinica: VariaveisGlobais.idClinica,
            id: id,
        }, {
            headers: { Authorization: `Bearer ${VariaveisGlobais.token}` }
        }).then(function (response) {
            console.log(response);
            if (response.data.error == true)  reject(true);
            resolve(response.data.data);
        }).catch(function(error) {
            console.log(error);
            reject(true);
        });
    });
}




export const carregaMensagensProntas = function() {
    return new Promise((resolve, reject) => {
        axios.get(`${URL_API}mensagem/prontas?idClinica=${VariaveisGlobais.idClinica}`,{
            headers: { Authorization: `Bearer ${VariaveisGlobais.token}` }
        }).then(function (response) {
            console.log(response);
            if (response.data.error == true)  reject(true);
            resolve(response.data.data);
        }).catch(function(error) {
            console.log(error);
            reject(true);
        });
    });
}



export const alterarConfiguracao = function(tipo, conteudo) {
    return new Promise((resolve, reject) => {
        axios.post(`${URL_API}clinica/configuracao`, {
            idClinica: VariaveisGlobais.idClinica,
            nome: tipo,
            valor: conteudo
        }, {
            headers: { Authorization: `Bearer ${VariaveisGlobais.token}` }
        }).then(function (response) {
            console.log(response);
            if (response.data.error == true)  reject(true);
            resolve(response.data.data);
        }).catch(function(error) {
            console.log(error);
            reject(true);
        });
    });
}

export const salvarConfiguracao = function(tipo, valor) {
    return new Promise((resolve, reject) => {
        axios.post(`${URL_API}clinica/configuracao`, {
            idClinica: VariaveisGlobais.idClinica,
            nome: tipo,
            valor: valor
        }, {
            headers: { Authorization: `Bearer ${VariaveisGlobais.token}` }
        }).then(function (response) {
            console.log(response);
            if (response.data.error == true)  reject(true);
            resolve(response.data.data);
        }).catch(function(error) {
            console.log(error);
            reject(true);
        });
    });
}


export const pegarClinicaConfiguracao = function(nome) {
    let query = `idClinica=${VariaveisGlobais.idClinica}&nome=${nome}`;
    return new Promise((resolve, reject) => {
        axios.get(`${URL_API}clinica/configuracao?${query}`, {
            headers: { Authorization: `Bearer ${VariaveisGlobais.token}` }
        }).then(function (response) {
            console.log(response);
            if (response.data.error == true)  reject(true);
            resolve(response.data.data);
        }).catch(function(error) {
            console.log(error);
            reject(true);
        });
    });
}


