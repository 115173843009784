import React, { useState, useEffect } from "react";
import URL_API from '../variaveis_globais/const';
import VariaveisGlobais from "variaveis_globais/variaveis_globais";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import socketIOClient from "socket.io-client";
import { inicializarInstancia, deslogarInstancia, estaConectado, qrCodeInstancia } from "data/api/gsConnectApi";


const socket = socketIOClient(URL_API);


const InstanciaWhastapp = (props) => {
  const [qrCode, setQrCode] = useState("");
  const [logado, setLogado] = useState(false);
  const [carregandoQrCode, setCarregandoQrCode] = useState(false);
  const [nomeInstancia, setNomeInstancia] = useState('');

  useEffect(() => {

    estaConectado(props.numeroInstancia).then((data) => {
      if (data.error) return alert(data.message)
      if (data && data.message && data.message.phone_connected) {
        setLogado(true);
        setCarregandoQrCode(false);
        setQrCode('');
      }
      setNomeInstancia(data.message.user.name ?? '');
    }).catch((erro) => {

    });

  }, []);


  async function aguardarQrCode() {
    while(true) {
      var qrCode = await qrCodeInstancia(props.numeroInstancia);
      var estaConectadoResultado = await estaConectado(props.numeroInstancia);

      if (estaConectadoResultado && estaConectadoResultado.message && estaConectadoResultado.message.phone_connected) {
        setLogado(true);
        setCarregandoQrCode(false);
        setQrCode('');
        setNomeInstancia(data.message.user.name ?? '');
        break;
      }

      if (qrCode && qrCode.qrcode) {
 
       setCarregandoQrCode(false);
       setQrCode(qrCode.qrcode);
      }
      await delay(5000);
     }
  }


  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

  return (<><Card>
    <Card.Header>
      <Card.Title as='h4' style={{ textAlign: 'center' }}>Whatsapp {props.numeroInstancia} - {nomeInstancia} </Card.Title>
    </Card.Header>
    <Card.Body style={{ height: 350 }} className='d-flex justify-content-center  align-items-center'>
    {(carregandoQrCode ? 'Carregando QrCode....': '')}
      {(carregandoQrCode == false && qrCode != '' ? <img src={qrCode} style={{ height: 300, width: 300 }}></img> : '')}
      {(carregandoQrCode == false && qrCode == '' && !logado ? <Button className="btn-fill" variant="success" onClick={() => {
        inicializarInstancia(props.numeroInstancia).then((data) => {
          if (data.message ==  
            "Instancia já iniciada.") {
              // carregar até qr code ficar disponivel
              setCarregandoQrCode(true);
              aguardarQrCode();
            }
        });
      }}>Carregar QrCode</Button> : '')}
      {( carregandoQrCode == false &&  logado == true ? <Button className="btn-fill" variant='link' onClick={() => {
        deslogarInstancia(props.numeroInstancia).then(() => {
          setQrCode('');
          setLogado(false);
        })
      }}>Logout</Button> : '')}
    </Card.Body>
  </Card></>)
}


function Typography() {

  return (
    <>
      <Col md='12'>
        <Card>
          <Card.Header>
            <Card.Title as='h3'>Configurações</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md='6'>
                <InstanciaWhastapp numeroInstancia='1' />
              </Col>
              <Col md='6'>
                <InstanciaWhastapp numeroInstancia='2' />
              </Col>
            </Row>
          </Card.Body>
          <Card.Body>
            <Row>
              <Col md='6'>
                <InstanciaWhastapp numeroInstancia='3' />
              </Col>
              <Col md='6'>
                <InstanciaWhastapp numeroInstancia='4' />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}

export default Typography;
