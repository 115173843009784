import URL_API from "../variaveis_globais/const";

export var FormatarDateParaDDMMYYYY = function(data) {
    if (!data)return;
    return `${ data.getDate() < 10? '0' + data.getDate() : data.getDate()}/${(data.getMonth() + 1) < 10? '0' + (data.getMonth() + 1) : (data.getMonth() + 1)}/${data.getFullYear()}`;
}


export var FormatarDateParaYYYYMMDD = function(data) {
    if (!data)return;
    return `${data.getFullYear()}-${(data.getMonth() + 1 )< 10? '0' + (data.getMonth() + 1) : data.getMonth() + 1}-${ data.getDate() < 10? '0' + data.getDate() : data.getDate()}`;
}



export var FormatarStrDateParaDDMMYYYYHHMM = function(data) {
    if (!data)return;
    data = new Date(Date.parse(data));
    (new Date()).getHours()
    return `${FormatarDateParaDDMMYYYY(data)} ${data.getHours()}:${data.getMinutes()}`;
}


export const gerarLinkDownload = function(idClinica, idMensagem) {
    return `${URL_API}documento/?idClinica=${idClinica}&idMensagem=${idMensagem}`;
}

export const  pegarParametroUrl= function(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});
