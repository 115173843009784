import { Col, Container, Form, Row } from "react-bootstrap";

function InicioLayout() {
    return (<>
        <Col style={{ backgroundColor: 'green', height: '100vh' }}>
            <Row >
                <Col md='12' style={{ height: '100vh' }} className='d-flex align-items-center'>

                    <Row className=' text-center m-3 text-white'>
                        <label><br /><br /> <span style={{fontSize: 50}}>gsConnect</span> <br /><br /> <span style={{fontSize: 25}}>Agora o gsDoctor tem integração direta com o Whatsapp que possibilita o envio de confirmações de agendamento, pré-cadastros, pré-consultas, documentos, mensagens de aniversário, laudos e resumos de ciclo com apenas 
                         <b> um clique</b>.</span> <br /> <br />  <b style={{fontSize: 20}}>Gostou?  </b>
                                  <span style={{fontSize: 20}}>Siga o caminho abaixo para ver o video de apresentação ou entre em contato conosco.</span><br />
                            <b  style={{fontSize: 20}}>Caminho:</b> <span style={{fontSize: 20}}>Siga o caminho abaixo para ver o video de apresentação ou entre em contato conosco.</span></label>
                    </Row>
                </Col>
                <Col md='6'>
                    <Row>

                    </Row>
                </Col>
            </Row>
        </Col>
    </>);
}

export default InicioLayout;