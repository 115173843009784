import { alterarConfiguracao, pegarClinicaConfiguracao, pegarConfiguracao, salvarConfiguracao } from "../data/api/gsConnectApi";
import React, { forwardRef, useEffect, useState, useRef, setState } from "react";
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  FormLabel,
} from "react-bootstrap";
import Select2Component from "components/select2Component";
import { TipoRespostaSelect2Component } from "components/select2/tipoRespotaSelect2Component";
import { fileToBase64 } from "utils/utils";
import { Label } from "reactstrap";

function TableList() {
  
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h5">Mensagens</Card.Title>
                <p className="card-category">
                  Configuração das respostas automáticas.
                </p>
              </Card.Header>
              <Card.Body className="">
                <Row>
                  <Col><FormLabel as='h4'>Dicas</FormLabel></Col>
                </Row>
                <Row className="mb-1">
                  <Col><span>1 - Utilize *TEXTO* para formatar o texto para negrito. Ex: </span><span>Olá *PacienteTeste* obrigado por confirmar seu agendamento.</span><span> Prévia: Olá <b><span>PacienteTeste</span></b> obrigado por confirmar seu agendamento.</span></Col>
                </Row>
                <Row className="mb-1">
                  <Col><span>2 - Utilize _TEXTO_ para formatar o texto para itálico. Ex: </span><span>Olá _PacienteTeste_ obrigado por confirmar seu agendamento.</span><span> Prévia: Olá <i><span>PacienteTeste</span></i> obrigado por confirmar seu agendamento.</span></Col>
                </Row>
                <Row className="mb-1">
                  <Col><span>3 - Utilize ~TEXTO~ para tachar o texto. Ex: </span><span>Olá ~PacienteTeste~ obrigado por confirmar seu agendamento.</span><span> Prévia: Olá <b><span style={{ textDecoration: 'line-through' }}>PacienteTeste</span></b> obrigado por confirmar seu agendamento.</span></Col>
                </Row>
                <Col md='12 mt-3'>
                  <Card>
                    <Card.Body>
                      <Card.Title as="h5" className="mb-3"><Row className="ml-3">Confirmação de agendamento <SelecionarWhatsappComponent tipo={'InstanciaMensagemConfirmacaoAgendamento'}/></Row></Card.Title>
                      <ul>
                        <li>$nomePaciente: inserir nome da paciente</li>
                        <li>$nomeProcedimento: inserir procedimento agendado</li>
                        <li>$nomeMedico: inserir nome do médico da paciente</li>
                        <li>$nomeDiaDaSemana: inserir dia da semana</li>
                        <li>$dataAgendamento: inserir data do agendamento</li>
                        <li>$horarioAgendamento: inserir horário do agendamento</li>
                        <li>$link: inserir link para acessar a confirmação do agendamento</li>
                      </ul>
                      <Form>
                        <ConfiguracaoItem titulo={'Mensagem ao enviar a confirmação de agendamento'} tipo={'MensagemConfirmaçãoAgendamento'}></ConfiguracaoItem>
                        <ConfiguracaoItem titulo={'Mensagem ao confirmar o agendamento'} tipo={'MensagemConfirmaçãoAgendamentoAoConfirmar'}></ConfiguracaoItem>
                        <ConfiguracaoItem titulo={'Mensagem ao cancelar o agendamento'} tipo={'MensagemConfirmaçãoAgendamentoAoCancelar'}></ConfiguracaoItem>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md='12 mt-3'>
                  <Card>
                    <Card.Body>
                      <Card.Title as="h5" className="mb-3"><Row className="ml-3">Pré cadastro<SelecionarWhatsappComponent tipo={'InstanciaMensagemPreCadastro'}/></Row></Card.Title>
                      <ul>
                        <li>$nomePaciente: inserir nome da paciente</li>
                        <li>$link: inserir link para acessar o pré cadastro</li>
                        <li>$dataValidadeLink: inserir data de validade do link</li>
                      </ul>
                      <Form>
                        <ConfiguracaoItem titulo={'Mensagem ao enviar o pré cadastro'} tipo={'MensagemPreCadastro'}></ConfiguracaoItem>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md='12 mt-3'>
                  <Card>
                    <Card.Body>
                      <Card.Title as="h5" className="mb-3"><Row className="ml-3">Pré consulta<SelecionarWhatsappComponent tipo={'InstanciaMensagemPreConsulta'}/></Row></Card.Title>
                      <ul>
                        <li>$nomePaciente: inserir nome da paciente</li>
                        <li>$link: inserir link para acessar o pré cadastro</li>
                        <li>$dataValidadeLink: inserir data de validade do link</li>
                      </ul>
                      <Form>
                        <ConfiguracaoItem titulo={'Mensagem ao enviar a pré consulta'} tipo={'MensagemPreConsulta'}></ConfiguracaoItem>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md='12 mt-3'>
                  <Card>
                    <Card.Body>
                      <Card.Title as="h5" className="mb-3"><Row className="ml-3">Lembrete de agendamento<SelecionarWhatsappComponent tipo={'InstanciaMensagemLembreteAgendamento'}/></Row></Card.Title>
                      <ul>
                        <li>$nomePaciente: inserir nome da paciente</li>
                        <li>$nomeProcedimento: inserir procedimento agendado</li>
                        <li>$nomeMedico: inserir nome do médico da paciente</li>
                        <li>$nomeDiaDaSemana: inserir dia da semana</li>
                        <li>$dataAgendamento: inserir data do agendamento</li>
                        <li>$horarioAgendamento: inserir horário do agendamento</li>
                        <li>$link: inserir link para acessar a confirmação do agendamento</li>
                      </ul>
                      <Form>
                        <ConfiguracaoItem titulo={'Mensagem ao enviar o lembrete de agendamento'} tipo={'MensagemLembreteAgendamento'}></ConfiguracaoItem>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md='12 mt-3'>
                  <Card>
                    <Card.Body>
                      <Card.Title as="h5" className="mb-3"><Row className="ml-3">Questionário<SelecionarWhatsappComponent  tipo={'InstanciaMensagemQuestionario'}/></Row></Card.Title>
                      <ul>
                        <li>$nomePaciente: inserir nome da paciente</li>
                        <li>$nomeQuestionario: inserir nome do questionário</li>
                        <li>$link: inserir link para acessar o pré cadastro</li>
                      </ul>
                      <Form>
                        <ConfiguracaoItem titulo={'Mensagem ao enviar o questionário'} tipo={'MensagemQuestionario'}></ConfiguracaoItem>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md='12 mt-3'>
                  <Card>
                    <Card.Body>
                      <Card.Title as="h5" className="mb-3"><Row className="ml-3">Mensagem de aniversário<SelecionarWhatsappComponent tipo={'InstanciaMensagemAniversario'} /></Row></Card.Title>
                      <li>$nomePaciente: inserir nome da paciente</li>
                      <Form>
                        <ConfiguracaoItemMensagemAniversarioClasse></ConfiguracaoItemMensagemAniversarioClasse>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md='12 mt-3'>
                  <Card>
                    <Card.Body>
                      <Card.Title as="h5" className="mb-3"><Row className="ml-3">Texto<SelecionarWhatsappComponent  tipo={'InstanciaMensagemTexto'}/></Row></Card.Title>
                      <ul>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md='12 mt-3'>
                  <Card>
                    <Card.Body>
                      <Card.Title as="h5" className="mb-3"><Row className="ml-3">Pdf<SelecionarWhatsappComponent  tipo={'InstanciaMensagemPdf'}/></Row></Card.Title>
                      <ul>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md='12 mt-3'>
                  <Card>
                    <Card.Body>
                      <Card.Title as="h5" className="mb-3"><Row className="ml-3">Resumo de ciclo<SelecionarWhatsappComponent  tipo={'InstanciaMensagemResumoCiclo'}/></Row></Card.Title>
                      <ul>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md='12 mt-3'>
                  <Card>
                    <Card.Body>
                      <Card.Title as="h5" className="mb-3"><Row className="ml-3">Laudo Espermograma<SelecionarWhatsappComponent  tipo={'InstanciaMensagemLaudoEspermograma'}/></Row></Card.Title>
                      <ul>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md='12 mt-3'>
                  <Card>
                    <Card.Body>
                      <Card.Title as="h5" className="mb-3"><Row className="ml-3">GED (Anexos)<SelecionarWhatsappComponent  tipo={'InstanciaMensagemGED'}/></Row></Card.Title>
                      <ul>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md='12 mt-3'>
                  <Card>
                    <Card.Body>
                      <Card.Title as="h5" className="mb-3"><Row className="ml-3">Nota Fiscal<SelecionarWhatsappComponent  tipo={'InstanciaMensagemNotaFiscal'}/></Row></Card.Title>
                      <ul>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>
    </>
  );
}

class  SelecionarWhatsappComponent extends React.Component {

  state = {};

  state = {
    carregando: true,
    selecionado: 1,
    nomeSelecionado: 'Whatsapp 1',
  };

  componentDidMount() {
    pegarClinicaConfiguracao(this.props.tipo).then((json) => {
      if (json && json.valor) {
        this.state.selecionado = json.valor;
        this.state.nomeSelecionado = 'Whatsapp ' + json.valor;
        this.setState(this.state);
      }
    });
  }

  render() {
return <>
      <Col></Col>
      <Col md='2'>
        <Select
            value={{value: this.state.selecionado, label: this.state.nomeSelecionado}}
              defaultValue={{value: this.state.selecionado, label: this.state.nomeSelecionado}}
              onChange={(valor) => {
                alterarConfiguracao(this.props.tipo,valor.value ).then(() => {
                  alert('Configuração salva.');
                }).catch(() => {
          
                });
             
                this.setState({ selecionado: valor.value, nomeSelecionado: valor.label });
              }}
              options={[
                { value: 1, label: 'Whatsapp 1' },
                { value: 2, label: 'Whatsapp 2' },
              ]}
            ></Select></Col>
    </>
  }
}

const ConfiguracaoItem = (props) => {

  const configuracaoReference = useRef();

  useEffect(() => {
    pegarConfiguracao(props.tipo).then((dados) => {
      configuracaoReference.current.value = dados.valor;
    }).catch(erro => {
      console.log(erro);
    }, [])
  });

  return (
    <>
      <Row className="align-items-center">
        <Col md='3'><Form.Label >{props.titulo}</Form.Label></Col>
        <Col md='5'><Form.Control type="text" as="textarea" ref={configuracaoReference} style={{ height: 150 }}></Form.Control></Col>
        <Col md='1'><Button variant="success" className="btn-fill" size="sm" onClick={() => {
          console.log(props);
          alterarConfiguracao(props.tipo, configuracaoReference.current.value).then(() => {
            alert('Configuração salva.');
          }).catch(() => {

          });
        }}>Salvar</Button></Col>
      </Row>
    </>
  );
}


class ConfiguracaoItemMensagemAniversarioClasse extends React.Component {
  state = {};

  state = {
    carregando: true,
    tipo: 'Texto',
    textoConfiguracao: '',
    imagemConfiguracao: ''
  };

  componentDidMount() {
    pegarClinicaConfiguracao('MensagemAniversario').then((json) => {
      if (json && json.valor) {
        if (json.valor.includes('data:image')) {
          this.state.imagemConfiguracao = json.valor;
          this.state.tipo = 'Imagem';
        } else {
          this.state.textoConfiguracao = json.valor;
          this.state.tipo = 'Texto';
        }
        console.log(this.state);
        this.setState(this.state);
      }
    });
  }



  render() {
    return (
      <>

        <Row className="align-items-center">
          <Col md="2">
            <Form.Label>{"Mensagem de aniversário"}</Form.Label>
          </Col>
          <Col md="1">
            {" "}
            <Select
            value={{value: this.state.tipo, label: this.state.tipo}}
              defaultValue={{value: this.state.tipo, label: this.state.tipo}}
              onChange={(valor) => this.setState({ tipo: valor.value })}
              options={[
                { value: "Texto", label: "Texto" },
                { value: "Imagem", label: "Imagem" },
              ]}
            ></Select>
          </Col>
          <Col md="5">
            {this.state.tipo == null ? (
              ""
            ) : this.state.tipo == "Texto" ? (
              <Form.Control
                type="text"
                as="textarea"
                value={this.state.textoConfiguracao}
                style={{ height: 150 }}
                onChange={(event) => {
                  this.setState({ textoConfiguracao: event.target.value });
                }}
              ></Form.Control>
            ) : (
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Seleciona uma imagem</Form.Label>
                <Form.Control type="file"  accept="image/png, image/gif, image/jpeg"  onChange={async (event) => {
                  let arquivoBase64 = '';
                  if (event.target.files.length > 0) {
                    arquivoBase64 = await fileToBase64(event.target.files[0]);
                  }
                  this.setState({imagemConfiguracao: arquivoBase64})
                }} />
              </Form.Group>
            )}{" "}

          </Col>
          <Col md="1">
            <Button
              variant="success"
              className="btn-fill"
              size="sm"
              onClick={() => {
                const configuracao =
                  this.state.tipo == "Texto"
                    ? this.state.textoConfiguracao
                    : this.state.imagemConfiguracao;

                salvarConfiguracao('MensagemAniversario', configuracao).then(() => {
                  alert('Configuração salva.');
                }).catch(() => {

                });
              }}
            >
              Salvar
            </Button>
          </Col>
        </Row>
        {this.state.tipo == 'Imagem' && this.state.imagemConfiguracao != '' ? 
        <Row className="align-items-center">
              <Col md='3'></Col>
              <Col>
              <img src={this.state.imagemConfiguracao }  style={{height: 200, width:200}}></img>
              </Col>
        </Row> : ''}
      </>
    );
  }

}

export default TableList;
