import Select2Component from 'components/select2Component';
import React, {Component, forwardRef, useImperativeHandle, useState, useRef} from 'react';



export var TipoSelect2Component = forwardRef((props, _ref) => {
    return (
        <Select2Component ref={_ref}   items={[
            { value: 'Confirmação de Agendamento', label: 'Confirmação de Agendamento' },
            { value: 'Questionário', label: 'Questionário' },
            { value: 'Pré cadastro', label: 'Pré cadastro' },
            { value: 'Pré consulta', label: 'Pré consulta' },
            { value: 'Aniversário', label: 'Aniversário' },
            // { value: 'Nota fiscal', label: 'Nota fiscal' },
            // { value: 'Documentos', label: 'Documentos' },
            { value: 'Laudo Espermograma', label: 'Laudo Espermograma' },
            { value: 'Resumo de ciclo', label: 'Resumo de ciclo' },
            { value: 'GED', label: 'GED' }
        ]}/>
    )
});