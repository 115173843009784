import Select2Component from 'components/select2Component';
import React, {forwardRef, useEffect, useId, useState} from 'react';
import axios from 'axios';
import VariaveisGlobais from 'variaveis_globais/variaveis_globais';

export var StatusSelect2Component = forwardRef((props, _ref) => {
    
    const [items, setaItems] = useState([]);

    useEffect(() => {
        var statues = [
            {
                value: 'TodoStatus',
                label: 'Todos',
            },
            {
                value: 1,
                label: 'Agendado',
            },
            {
                value: 8,
                label: 'Confirmado',
            }
            ,
            {
                value: 7,
                label: 'Presença/Em Espera',
            },
            {
                value: 9,
                label: 'Em atendimento',
            }
            ,
            {
                value: 24,
                label: 'Proced. Cancelado',
            }
            ,
            {
                value: 2,
                label: 'Atendimento Finalizado',
            }
            ,
            {
                value: 5,
              label: 'Não compareceu',
            }
          ]

          setaItems(statues);

    },[])

    return (
        <Select2Component isMulti={true} ref={_ref}  items={items}/>
    )
});