import React, {useState, useEffect} from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import videoApresentacao from '../assets/video/apresentacao.mp4';


function AjudaView() {
  return (
    <>
      <Row>
        <Col md='4'>
          <Card>
            <Card.Header>
              <Card.Title>Apresentação da ferramenta</Card.Title>
            </Card.Header>
            <Card.Body>
            <iframe src={videoApresentacao} width={500} height={500} allowFullScreen={true}></iframe>
            </Card.Body>
          </Card>
        </Col>
      </Row>
     
    </>
  );
}

export default AjudaView;
